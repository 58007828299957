import * as React from "react";
import {useState} from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Flex,
    HStack,
    Image,
    Spinner,
    Text,
    Textarea,
    useToast,
    VStack
} from "@chakra-ui/react";
import {BASE_URL} from "./test-utils";
import axios from "axios";

export const QrCard = (props: any) => {
    console.log("qr card render");
    let item = props.item;

    const [url, setUrl] = useState(`${BASE_URL}/${item['index']}/qrcode.png`);
    const [loading, setLoading] = useState(false);
    const [loadingText] = useState('正在加载中...');
    const [mchid, setMchid] = useState(item['info'] ? item['info']['merchant_code'] : '未知');
    const [mchname, setMchname] = useState(item['info'] ? item['info']['company_name'] : '未知');
    const [status, setStatus] = useState(item['status']);
    const [appid, setAppid] = useState('');
    const [result, setResult] = useState('');
    // 页面截图base64
    const [launch_png, setLaunchPng] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVR4Ae3XsQ2CMBQF4F')
    const toast = useToast()
    const error_toast = (title: string, description: string = '') => {
        toast({
            title: title,
            description: description,
            status: "error",
            position: "top",
            duration: 9000,
            isClosable: true,
        })
    }

    // 设置个定时任务每一秒查一次二维码状态，如果已经登录就刷新
    React.useEffect(() => {
        const timer = setInterval(async () => {
            if (loading) {
                return
            }
            let resp = await axios.get(`${BASE_URL}/islogin?index=${item['index']}`)
            let data = resp.data
            setStatus(data.state)
            if (data.image) {
                setLaunchPng("data:image/png;base64," + data.image)
            }

        }, 5000);
        return () => clearInterval(timer);
    });

    const submit = async () => {
        console.log('submit');
        setResult('')

        // appid 一行一个，用空字符拆分一行变为2个元素的数组
        let appids = appid.split('\n');
        let data = []
        for (let i = 0; i < appids.length; i++) {
            let appid = appids[i].trim();
            if (!appid) {
                error_toast('空行不行', appid);
                continue;
            }
            // 用正则匹配空字符拆分
            let appidx = appid.split(/\s+/);
            if (appidx.length > 2) {
                console.log('appid格式错误');
                error_toast('不能有三个参数', appid);
                continue
            }
            // 第一个必须是字母加数字wx开头的组合，第二个必须是中文加标点符号的组合
            if (!/^[a-zA-Z0-9_]+$/.test(appidx[0]) || !appidx[0].startsWith('wx')) {
                error_toast('必须为wx开头并且字母加数字的组合', appidx[0]);
                continue
            }
            if (appidx.length === 2 && !/[\u4e00-\u9fa5]+/.test(appidx[1])) {
                error_toast('不为中文+标点符号组合', appidx[1]);
                continue
            }

            // 验证通过
            data.push({appid: appidx[0], name: appidx[1]})
        }
        console.log(data);
        if (!data.length) {
            error_toast('没有合规的可以提交');
            return
        }

        setLoading(true);
        let resp = await axios.post(`${BASE_URL}/bind`, {data: data, index: item['index']})
        // console.log(resp.data);
        let result = '';
        for (let i = 0; i < resp.data.length; i++) {
            let item = resp.data[i];
            let e = ''
            if (item.error && item.error.msg) {
                e = item.error.msg
            }
            result += item.appid + ' ' + item.status + " " + e + '\n';
        }
        setResult(result)
        setLoading(false);

    }
    const reflush = async () => {
        console.log('reflush');
        setLoading(true);
        let resp = await axios.get(`${BASE_URL}/qrcode?index=${item['index']}`)
        let data = await resp.data
        console.log(data);
        if (data && data.data) {
            setMchid(data.data.merchant_code);
            setMchname(data.data.company_name);
            setStatus('已登录');
        }
        setUrl(`${BASE_URL}/${item['index']}/qrcode.png?${new Date().getTime()}`)
        setLoading(false);

    }
    const logout = async () => {
        console.log('logout');
        setLoading(true);
        await axios.get(`${BASE_URL}/logout?index=${item['index']}`)
        setStatus('未登录');
        setLoading(false);

    }

    return (
        <Box position="relative">
            <Card key={props.index} w="300px" borderRadius={10}>
                <CardBody>
                    <Center>
                        <VStack alignItems={
                            "flex-start"
                        }>
                            <Image
                                src={url}
                                borderRadius={10}
                                border={"1px solid"}
                                fallback={
                                    <Box w="288px" h="300px" borderRadius={10} border={"1px solid"}>
                                        <Center h="100%">
                                            <Button mt={2} onClick={reflush}>点击刷新</Button>
                                        </Center>
                                    </Box>
                                }/>

                            <Text>扫描上面的二维码</Text>
                            <Text>当前:{status}</Text>
                            <Text>商户号id:{mchid}</Text>
                            <Text>主体:{mchname}</Text>
                            <Textarea placeholder={"appid 主体信息\nappid\nappid 主体信息"}
                                      value={appid}
                                      h={"200px"}
                                      onChange={(e) => setAppid(e.target.value)}
                                // 字体大小
                                      fontSize="8"
                            />
                            <HStack>
                                <Button onClick={submit}>提交</Button>
                                <Button onClick={reflush}>刷新二维码</Button>
                                <Button onClick={logout}>退出登录</Button>
                            </HStack>
                            <Textarea
                                value={result}
                                fontSize="8"
                                h={"200px"}
                            />
                            <Image src={launch_png}/>
                        </VStack>
                    </Center>
                </CardBody>
            </Card>
            {/* 遮罩层 */}
            {loading && (
                <Box
                    position="absolute"
                    borderRadius={10}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundColor="rgba(0, 0, 0, 0.5)"
                    zIndex={9999}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Flex direction="column" alignItems="center">
                        <Spinner size="xl" color="white"/>
                        <Text color="white" mt={2}>{loadingText}</Text>
                    </Flex>
                </Box>
            )}
        </Box>

    );
};
