import * as React from "react"
import {useEffect, useState} from "react"
import {ChakraProvider, Flex, theme, useToast} from "@chakra-ui/react"
import {ColorModeSwitcher} from "./ColorModeSwitcher";
import {BASE_URL} from "./test-utils";
import {QrCard} from "./QrCard";
import axios from "axios";

export const App = () => {
    // 从本地3000端口/info获取列表信息
    const [data, setData] = useState([]);
    const toast = useToast()

    // 用于获取数据，第二个参数为空数组，表示只在第一次渲染时获取数据
    useEffect(() => {
        axios.get(`${BASE_URL}/info`)
            .then(data => data.data)
            .then(async data => {
                console.log(data);
                if (data.length === 0) {
                    await axios.get(`${BASE_URL}/qrcode?index=0`)
                }
                setData(data);
            }).catch(e => {
            console.log("获取数据失败,服务可能正在重启");
            toast({
                title: "获取数据失败,服务可能正在重启",
                status: "error",
                position: "top",
                duration: 9000,
                isClosable: true,
            })
        });
    }, [toast]);
    console.log("app render");

    return (
        <ChakraProvider theme={theme}>
            <ColorModeSwitcher position="absolute" right="10px" top="10px"/>

            <Flex gap={2} margin={10}>
                {data.map((item, index) => {
                    return <QrCard item={item} index={index} key={index}/>
                })}

            </Flex>

        </ChakraProvider>)
}
